(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Cell = /** @class */ (function () {
    function Cell(content, colspan, rowspan) {
        if (colspan === void 0) { colspan = 1; }
        if (rowspan === void 0) { rowspan = 1; }
        this.highlight = false;
        this.content = content;
        this.colspan = colspan;
        this.rowspan = rowspan;
        // hightlight is only set once with initial content
        if (/^!highlight/i.test(this.content)) {
            this.highlight = true;
            this.content = this.content.replace(/^!highlight /i, '');
            this.content = this.content.replace(/^!highlight/i, '');
        }
    }
    Cell.prototype.isHighlighted = function () {
        return this.highlight;
    };
    Cell.prototype.getContent = function () {
        return this.content;
    };
    Cell.prototype.setContent = function (content) {
        this.content = content;
    };
    Cell.prototype.getRowspan = function () {
        return this.rowspan;
    };
    Cell.prototype.setRowspan = function (rowspan) {
        this.rowspan = rowspan;
    };
    Cell.prototype.getColspan = function () {
        return this.colspan;
    };
    Cell.prototype.setColspan = function (colspan) {
        this.colspan = colspan;
    };
    Cell.prototype.toString = function () {
        var json = {
            content: this.content,
            colspan: this.colspan,
            rowspan: this.rowspan,
            highlight: this.highlight
        };
        return JSON.stringify(json, null, 2);
    };
    return Cell;
}());
exports.default = Cell;

},{}],2:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var options_1 = require("./options");
var Datasource = /** @class */ (function () {
    function Datasource($orginalTableModule, options) {
        this.header = [];
        this.data = [];
        this.numberOfCols = 0;
        this.numberOfDataRows = 0;
        this.dataValid = true;
        this.post = $('<div>');
        if (undefined === options) {
            options = new options_1.default();
        }
        this.options = options;
        this.$tableModule = $orginalTableModule.clone();
        this.$table = this.$tableModule.find('table');
        this.normalizeData();
        this.setHeader();
        this.validateDataStructure();
        this.setData();
        this.setPost();
    }
    Datasource.prototype.normalizeData = function () {
        this.$table.find('td[colspan]').each(function (index, item) {
            var colspanAttr = $(item).attr('colspan');
            var colspan = parseInt(colspanAttr, 10);
            $(item).removeAttr('colspan');
            var $cell = $(item);
            for (var i = 1; i < colspan; i += 1) {
                $(item).after($cell.clone());
            }
        });
        this.$table.find('td[rowspan]').each(function (loopIndex, item) {
            var rowspanAttr = $(item).attr('rowspan');
            var rowspan = parseInt(rowspanAttr, 10);
            var $cell = $(item);
            $cell.removeAttr('rowspan');
            var $row = $(item).parent('tr');
            var index = $row.find('td').index($cell);
            for (var i = 1; i < rowspan; i += 1) {
                $row = $row.next('tr');
                $row.find('td:nth(' + index + ')').before($cell.clone());
            }
        });
    };
    Datasource.prototype.setHeader = function () {
        var _this = this;
        if (true === this.options.firstLineHeaders) {
            this.$table.find('tr:first-child td, tr:first-child th').each(function (index, item) {
                _this.header[index] = $(item).html().trim();
            });
            this.$table.find('tr:first-child').remove();
        }
    };
    Datasource.prototype.getHeader = function () {
        return this.header;
    };
    Datasource.prototype.validateDataStructure = function () {
        var $rows = this.$table.find('tr');
        this.numberOfDataRows = $rows.length;
        var maxNumberOfCols = [];
        maxNumberOfCols.push(this.getHeader().length);
        $rows.each(function () {
            maxNumberOfCols.push($(this).find('th, td').length);
        });
        this.numberOfCols = Math.max.apply(null, maxNumberOfCols);
        var unique = maxNumberOfCols.filter(function (value, index, self) { return self.indexOf(value) === index; });
        if (1 !== unique.length) {
            // at least on row as a different number of cols
            this.dataValid = false;
        }
    };
    Datasource.prototype.getOptions = function () {
        return this.options;
    };
    Datasource.prototype.getData = function () {
        return this.data;
    };
    Datasource.prototype.isDataValid = function () {
        return this.dataValid;
    };
    Datasource.prototype.getNumberOfCols = function () {
        return this.numberOfCols;
    };
    Datasource.prototype.getNumberOfRows = function () {
        return this.numberOfDataRows;
    };
    Datasource.prototype.setData = function () {
        var _this = this;
        this.$table.find('tr').each(function (index, item) {
            var row = [];
            $(item).find('td').each(function () {
                $(this).find('img').each(function () {
                    $(this).removeAttr('width');
                    $(this).removeAttr('height');
                    $(this).css('width', '');
                    $(this).css('height', '');
                });
                row.push($(this).html().trim());
            });
            _this.data.push(row);
        });
    };
    Datasource.prototype.getPost = function () {
        return this.post;
    };
    Datasource.prototype.setPost = function () {
        if (0 === this.$table.next().length) {
            return;
        }
        this.post = this.$table.next();
    };
    return Datasource;
}());
exports.default = Datasource;

},{"./options":5}],3:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var options_1 = require("./options");
var datasource_1 = require("./datasource");
var processor_1 = require("./processor");
var renderer_default_1 = require("./renderer-default");
var renderer_sliding_1 = require("./renderer-sliding");
var renderer_stacked_1 = require("./renderer-stacked");
var Frontend = /** @class */ (function () {
    function Frontend($responsiveTable) {
        var _this = this;
        this.$responsiveTable = $responsiveTable;
        this.setOptions();
        this.setDataSource();
        this.setProcessor();
        this.render();
        $(window).on('vf::viewportchanged', function () {
            _this.render();
        });
    }
    Frontend.prototype.setOptions = function () {
        var $options = this.$responsiveTable.find('.mod-responsive-table-options');
        if (0 !== $options.length) {
            this.options = new options_1.default($options);
        }
        else {
            this.options = new options_1.default();
        }
    };
    Frontend.prototype.getOptions = function () {
        return this.options;
    };
    Frontend.prototype.setDataSource = function () {
        this.dataSource = new datasource_1.default(this.$responsiveTable, this.options);
    };
    Frontend.prototype.getDataSource = function () {
        return this.dataSource;
    };
    Frontend.prototype.setProcessor = function () {
        this.processor = new processor_1.default(this.dataSource);
    };
    Frontend.prototype.getProcessor = function () {
        return this.processor;
    };
    Frontend.prototype.getRenderer = function (viewport) {
        if ('mobile' !== viewport) {
            return 'DefaultRenderer';
        }
        if ('sliding' === this.options.type) {
            // slide only if three or more cols
            if (3 <= this.dataSource.getHeader().length) {
                return 'SlidingRenderer';
            }
            else {
                return 'DefaultRenderer';
            }
        }
        else if ('stacked' === this.options.type) {
            return 'StackedRenderer';
        }
        return 'DefaultRenderer';
    };
    Frontend.prototype.rendererFactory = function (rendererName) {
        if ('StackedRenderer' === rendererName) {
            return new renderer_stacked_1.default(this.processor);
        }
        if ('SlidingRenderer' === rendererName) {
            return new renderer_sliding_1.default(this.processor);
        }
        return new renderer_default_1.default(this.processor);
    };
    Frontend.prototype.render = function () {
        this.$responsiveTable.html('');
        var rendererName = this.getRenderer(vf.util.layout(true));
        var renderer = this.rendererFactory(rendererName);
        this.$responsiveTable.append(renderer.render());
        this.$responsiveTable.append(this.dataSource.getPost());
        this.$responsiveTable.addClass(renderer.getClassNames().join(' '));
        renderer.afterRendered();
        vf['footnotes-delegate'].init(this.$responsiveTable);
    };
    return Frontend;
}());
exports.default = Frontend;

},{"./datasource":2,"./options":5,"./processor":6,"./renderer-default":7,"./renderer-sliding":8,"./renderer-stacked":9}],4:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Icons = /** @class */ (function () {
    function Icons() {
        this.svgRegEx = /(icon\:[\w|-]+)/i;
        this.shortCutPatterns = {
            leer: '&nbsp;',
            nein: '<svg class="i-xsml i-red"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-dialog-close-sml"></use></svg>',
            ja: '<svg class="i-xsml i-green"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-list-tick-sml"></use></svg>'
        };
        this.replacing = function (cell) {
            var content = cell.getContent();
            // is a shortcut
            if (undefined !== this.shortCutPatterns[content]) {
                cell.setContent(this.shortCutPatterns[content]);
                return;
            }
            if (true === this.svgRegEx.test(content)) {
                var replaced = content.replace(this.svgRegEx, function (match) {
                    var iconName = match.toLowerCase().replace('icon:', '');
                    return '<svg class="i-xsml i-grey"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/simplicity/svgdefs.svg#i-' + iconName + '-sml"></use></svg>';
                });
                cell.setContent(replaced);
                return;
            }
        };
    }
    Icons.prototype.isReplaceable = function (cell) {
        var content = cell.getContent();
        // is a shortcut
        if (undefined !== this.shortCutPatterns[content]) {
            return true;
        }
        if (true === this.svgRegEx.test(content)) {
            return true;
        }
        return false;
    };
    Icons.prototype.replace = function (cell) {
        if (false === this.isReplaceable(cell)) {
            return;
        }
        this.replacing(cell);
    };
    return Icons;
}());
exports.default = Icons;

},{}],5:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Options = /** @class */ (function () {
    function Options($options) {
        var _this = this;
        /**
         * @TODO Create interface for opts
         */
        this.opts = {
            type: 'sliding',
            width: 'full',
            firstColFixed: false,
            colWidthMode: 'auto',
            colWidths: Array.apply(null, { length: 5 }).map(function () { return 'auto'; }),
            firstLineHeaders: true,
            groupByFirstCol: false,
            joinRowsInGroup: [],
            joinAdjacentCols: true,
            createSubHeadlines: true,
            replaceWithIcons: false,
            zebra: false
        };
        this.$options = $options;
        if (undefined !== this.$options) {
            this.$options.remove();
            var parsedOptions_1 = $.extend({}, this.opts);
            this.$options.find('div[data-name]').each(function (index, item) {
                var name = $(item).data('name');
                var value = $(item).data('value');
                var type = $(item).data('type');
                name = _this.parseName(name);
                // prevent opts that are unkown
                if (undefined === parsedOptions_1[name]) {
                    return false;
                }
                parsedOptions_1[name] = _this.parseOption(value, type);
                return false;
            });
            this.opts = parsedOptions_1;
        }
        this.parseColWidth();
        this.checkAndResolvedependendopts();
    }
    Options.prototype.ucFirst = function (astring) {
        return astring.charAt(0).toUpperCase() + astring.slice(1);
    };
    Options.prototype.parseName = function (name) {
        var splittedName = name.split('-');
        for (var i = 1, x = splittedName.length; i < x; i += 1) {
            splittedName[i] = this.ucFirst(splittedName[i]);
        }
        return splittedName.join('');
    };
    Options.prototype.parseColWidth = function () {
        if (undefined === this.$options) {
            this.opts.colWidthMode = 'auto';
        }
        if ('auto' === this.opts.colWidthMode) {
            this.opts.colWidths = Array.apply(null, { length: 5 }).map(function () { return 'auto'; });
        }
        else {
            this.opts.colWidths = [];
            for (var i = 0, x = 5; i < x; i += 1) {
                var colWidth = this.$options.find('div[data-col-index="' + i + '"]').attr('data-col-width');
                if (undefined === colWidth || '' === colWidth) {
                    colWidth = 'auto';
                }
                this.opts.colWidths.push(colWidth);
            }
        }
    };
    Options.prototype.parseOption = function (value, type) {
        if (type === void 0) { type = 'string'; }
        if ('string' === type) {
            value = '' + value;
        }
        if ('boolean' === type) {
            if ('true' === value || true === value) {
                value = true;
            }
            else {
                value = false;
            }
        }
        if ('array' === type) {
            if (undefined === value.split) {
                return [];
            }
            var array = value.split(',');
            value = array.map(function (item) {
                item = $.trim(item);
                if ($.isNumeric(item)) {
                    item = 1 * item;
                }
                return item;
            });
        }
        return value;
    };
    Options.prototype.checkAndResolvedependendopts = function () {
        if (false === this.opts.groupByFirstCol) {
            this.opts.joinRowsInGroup = [];
        }
        if (false === this.opts.joinAdjacentCols) {
            this.opts.createSubHeadlines = false;
        }
        if ('stacked' === this.opts.type) {
            this.opts.firstColFixed = false;
        }
        // @TODO consider type and width
    };
    Options.prototype.toString = function () {
        var json = {
            type: this.opts.type,
            width: this.opts.width,
            firstColFixed: this.opts.firstColFixed,
            colWidthMode: this.opts.colWidthMode,
            colWidths: this.opts.colWidths,
            firstLineHeaders: this.opts.firstLineHeaders,
            groupByFirstCol: this.opts.groupByFirstCol,
            joinRowsInGroup: this.opts.joinRowsInGroup,
            joinAdjacentCols: this.opts.joinAdjacentCols,
            createSubHeadlines: this.opts.createSubHeadlines,
            replaceWithIcons: this.opts.replaceWithIcons,
            zebra: this.opts.zebra
        };
        return JSON.stringify(json, null, 2);
    };
    Object.defineProperty(Options.prototype, "type", {
        get: function () {
            return this.opts.type;
        },
        set: function (type) {
            if ('sliding' !== type && 'stacked' !== type) {
                type = 'sliding';
            }
            this.opts.type = type;
            this.checkAndResolvedependendopts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "width", {
        get: function () {
            return this.opts.width;
        },
        set: function (width) {
            if ('full' !== width && 'narrow' !== width) {
                width = 'full';
            }
            this.opts.width = width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "firstColFixed", {
        get: function () {
            return this.opts.firstColFixed;
        },
        set: function (firstColFixed) {
            this.opts.firstColFixed = firstColFixed;
            this.checkAndResolvedependendopts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "colWidthMode", {
        get: function () {
            return this.opts.colWidthMode;
        },
        set: function (colWidthMode) {
            if ('auto' !== colWidthMode && 'custom' !== colWidthMode) {
                colWidthMode = 'auto';
            }
            this.opts.colWidthMode = colWidthMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "colWidths", {
        get: function () {
            return this.opts.colWidths;
        },
        set: function (colWidths) {
            if (true !== $.isArray(colWidths) || 5 !== colWidths.length) {
                colWidths = Array.apply(null, { length: 5 }).map(function () { return 'auto'; });
            }
            this.opts.colWidths = colWidths;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "firstLineHeaders", {
        get: function () {
            return this.opts.firstLineHeaders;
        },
        set: function (firstLineHeaders) {
            this.opts.firstLineHeaders = firstLineHeaders;
            this.checkAndResolvedependendopts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "groupByFirstCol", {
        get: function () {
            return this.opts.groupByFirstCol;
        },
        set: function (groupByFirstCol) {
            this.opts.groupByFirstCol = groupByFirstCol;
            this.checkAndResolvedependendopts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "joinRowsInGroup", {
        get: function () {
            return this.opts.joinRowsInGroup;
        },
        set: function (joinRowsInGroup) {
            this.opts.joinRowsInGroup = (true === $.isArray(joinRowsInGroup)) ? joinRowsInGroup : [];
            this.checkAndResolvedependendopts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "joinAdjacentCols", {
        get: function () {
            return this.opts.joinAdjacentCols;
        },
        set: function (joinAdjacentCols) {
            this.opts.joinAdjacentCols = joinAdjacentCols;
            this.checkAndResolvedependendopts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "createSubHeadlines", {
        get: function () {
            return this.opts.createSubHeadlines;
        },
        set: function (createSubHeadlines) {
            this.opts.createSubHeadlines = createSubHeadlines;
            this.checkAndResolvedependendopts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "replaceWithIcons", {
        get: function () {
            return this.opts.replaceWithIcons;
        },
        set: function (replaceWithIcons) {
            this.opts.replaceWithIcons = replaceWithIcons;
            this.checkAndResolvedependendopts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Options.prototype, "zebra", {
        get: function () {
            return this.opts.zebra;
        },
        set: function (zebra) {
            this.opts.zebra = zebra;
            this.checkAndResolvedependendopts();
        },
        enumerable: true,
        configurable: true
    });
    return Options;
}());
exports.default = Options;

},{}],6:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var row_1 = require("./row");
var icons_1 = require("./icons");
var Processor = /** @class */ (function () {
    function Processor(dataSource) {
        this.data = [];
        this.groupsIndex = 0;
        this.joinAdjacentCols = function () {
            var _this = this;
            if (true === this.options.joinAdjacentCols) {
                $(this.data).each(function (rowIndex) {
                    var actualRow = _this.data[rowIndex];
                    for (var i = 0, x = actualRow.getLength(); i < x; i += 1) {
                        var cell = actualRow.getCell(i);
                        if (0 === cell.getColspan()) {
                            continue;
                        }
                        var after = i + 1;
                        var same = 1;
                        while (after < x && actualRow.getCell(i).getContent() === actualRow.getCell(after).getContent()) {
                            actualRow.getCell(after).setColspan(0);
                            same += 1;
                            after += 1;
                        }
                        if (1 !== same) {
                            actualRow.getCell(i).setColspan(same);
                        }
                    }
                });
            }
        };
        this.dataSource = dataSource;
        this.options = this.dataSource.getOptions();
        this.icons = new icons_1.default();
        this.setHeader();
        this.setData();
        this.groupDataByFirstCol();
        this.joinRowsInGroup();
        this.joinAdjacentCols();
        this.replaceCellContentByIcon();
    }
    Processor.prototype.getOptions = function () {
        return this.options;
    };
    Processor.prototype.getDataSource = function () {
        return this.dataSource;
    };
    Processor.prototype.getHeader = function () {
        return this.header;
    };
    Processor.prototype.setHeader = function () {
        var _this = this;
        this.header = new row_1.default(0);
        $(this.dataSource.getHeader()).each(function (index, item) {
            _this.header.addCell(item);
        });
    };
    Processor.prototype.getData = function () {
        return this.data;
    };
    Processor.prototype.setData = function () {
        var _this = this;
        $(this.dataSource.getData()).each(function (rowIndex, dataRow) {
            var row = new row_1.default(rowIndex);
            $(dataRow).each(function (index, item) {
                row.addCell(item);
            });
            _this.data.push(row);
        });
    };
    Processor.prototype.groupDataByFirstCol = function () {
        var _this = this;
        if (true === this.options.groupByFirstCol) {
            $(this.data).each(function (rowIndex, row) {
                // last row
                if (_this.data.length === (rowIndex + 1)) {
                    return false;
                }
                var actualRow = _this.data[rowIndex];
                var actualFirstCol = actualRow.getCell(0);
                if (true === actualRow.isGrouped()) {
                    return false;
                }
                var nextRowIndex = rowIndex + 1;
                var nextRow = _this.data[nextRowIndex];
                var nextFirstCol = nextRow.getCell(0);
                while (actualFirstCol.getContent() === nextFirstCol.getContent()) {
                    if (false === actualRow.isGrouped()) {
                        // group with itself indicates group "leader"
                        actualRow.addGroupedWith(actualRow.getIndex());
                        actualRow.setGroupName(_this.groupsIndex.toString());
                        _this.groupsIndex += 1;
                    }
                    actualRow.addGroupedWith(nextRow.getIndex());
                    nextRow.addGroupedWith(actualRow.getIndex());
                    nextRow.setGroupName(actualRow.getGroupName());
                    nextRowIndex += 1;
                    // last row
                    if (_this.data.length === (nextRowIndex)) {
                        return false;
                    }
                    nextRow = _this.data[nextRowIndex];
                    nextFirstCol = nextRow.getCell(0);
                }
                return false;
            });
            $(this.data).each(function (index, item) {
                var row = _this.data[index];
                if (false === row.isGrouped()) {
                    return false;
                }
                if (true === row.isGroupsFirst()) {
                    row.getCell(0).setRowspan(row.getGroupedWith().length);
                }
                else {
                    row.getCell(0).setRowspan(0);
                }
                return false;
            });
        }
    };
    Processor.prototype.joinRowsInGroup = function () {
        var _this = this;
        if (0 !== this.groupsIndex && false !== this.options.joinRowsInGroup) {
            $(this.data).each(function (rowIndex, row) {
                // last row
                if (_this.data.length === (rowIndex + 1)) {
                    return false;
                }
                var actualRow = _this.data[rowIndex];
                if (false === actualRow.isGrouped() || false === actualRow.isGroupsFirst()) {
                    return false;
                }
                var groupedWith = actualRow.getGroupedWith();
                for (var x = 0; x < _this.options.joinRowsInGroup.length; x += 1) {
                    var cellIndex = _this.options.joinRowsInGroup[x];
                    var values = [];
                    for (var i = 0; i < groupedWith.length; i += 1) {
                        var groupRowIndex = groupedWith[i];
                        values.push(_this.data[groupRowIndex].getCell(cellIndex).getContent());
                    }
                    // We live "ts ignore" here for the moment, because this "uniqueSort" feature is not supported in jQuery 2
                    // and without "ts ignore" jabof is not being started till we merge our changes in develop
                    // @ts-ignore
                    var unique = $.uniqueSort(values);
                    if (1 === unique.length) {
                        for (var i = 0; i < groupedWith.length; i += 1) {
                            var groupRowIndex = groupedWith[i];
                            if (0 === i) {
                                _this.data[groupRowIndex].getCell(cellIndex).setRowspan(groupedWith.length);
                            }
                            else {
                                _this.data[groupRowIndex].getCell(cellIndex).setRowspan(0);
                            }
                        }
                    }
                }
                return false;
            });
        }
    };
    Processor.prototype.replaceCellContentByIcon = function () {
        var _this = this;
        if (true !== this.options.replaceWithIcons) {
            return;
        }
        for (var i = 0, x = this.header.getLength(); i < x; i += 1) {
            var headerCell = this.header.getCell(i);
            this.icons.replace(headerCell);
        }
        $(this.data).each(function (rowIndex) {
            var actualRow = _this.data[rowIndex];
            for (var j = 0, y = actualRow.getLength(); j < y; j += 1) {
                var cell = actualRow.getCell(j);
                _this.icons.replace(cell);
            }
        });
    };
    return Processor;
}());
exports.default = Processor;

},{"./icons":4,"./row":10}],7:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DefaultRenderer = /** @class */ (function () {
    function DefaultRenderer(processor) {
        this.checkIconsAndText = function () {
            this.$renderedTable.find('th svg, td svg').each(function () {
                var $parent = $(this).parent('th, td');
                // if parent .text is not empty, it contains text and icon
                if ('' !== $parent.text()) {
                    $parent.addClass('icon-and-text');
                }
            });
        };
        this.processor = processor;
        this.options = this.processor.getOptions();
        this.dataSource = this.processor.getDataSource();
    }
    DefaultRenderer.prototype.getOptions = function () {
        return this.options;
    };
    DefaultRenderer.prototype.createEmptyTable = function () {
        this.$wrapper = $('<div>');
        this.$wrapper.addClass('responsive-table-wrapper');
        this.$renderedTable = $('<table>');
        this.$wrapper.append(this.$renderedTable);
    };
    DefaultRenderer.prototype.createColGroup = function () {
        var colCount = this.processor.getHeader().getLength();
        var widths = this.processor.getOptions().colWidths;
        widths = widths.slice(0, colCount);
        if ('auto' === this.processor.getOptions().colWidthMode) {
            var widthPercent_1 = 100 / colCount + '%';
            widths = Array.apply(null, { length: colCount }).map(function () { return widthPercent_1; });
        }
        var $colgroup = $('<colgroup>');
        $(widths).each(function (index) {
            var width = widths[index];
            var $col = $('<col>');
            $col.css('width', width);
            $colgroup.append($col);
        });
        this.$renderedTable.prepend($colgroup);
    };
    DefaultRenderer.prototype.createHeader = function () {
        var header = this.processor.getHeader();
        if (0 === header.getLength()) {
            return;
        }
        var $header = $('<thead>');
        var $row = $('<tr>');
        $header.append($row);
        for (var i = 0, x = header.getLength(); i < x; i += 1) {
            var content = header.getCell(i).getContent();
            var $th = $('<th>');
            $th.html(content);
            $row.append($th);
        }
        this.$renderedTable.append($header);
    };
    DefaultRenderer.prototype.createBody = function () {
        var data = this.processor.getData();
        if (undefined === data || 0 === data.length) {
            return;
        }
        var $body = $('<tbody>');
        var that = this;
        var $lastRow;
        $(data).each(function () {
            var $row = $('<tr>');
            var row = this;
            if (true === row.isGrouped()) {
                $row.addClass('group');
            }
            else {
                $row.addClass('not_grouped');
            }
            if (true === row.isGroupsFirst()) {
                $row.addClass('group_first');
            }
            for (var i = 0, x = row.getLength(); i < x; i += 1) {
                var cell = row.getCell(i);
                if (0 === cell.getRowspan()) {
                    continue;
                }
                if (0 === cell.getColspan()) {
                    continue;
                }
                var $cell = $('<td>');
                if (true === cell.isHighlighted()) {
                    $cell.addClass('cell-highlight');
                }
                if (1 < cell.getRowspan()) {
                    $cell.attr('rowspan', cell.getRowspan());
                }
                if (1 < cell.getColspan()) {
                    $cell.attr('colspan', cell.getColspan());
                    if (true === that.options.createSubHeadlines && that.dataSource.getNumberOfCols() === cell.getColspan()) {
                        $row.addClass('subheadline');
                        $cell.addClass('subheadline');
                        $($lastRow).addClass('subheadline-before');
                    }
                }
                $cell.html(cell.getContent());
                $row.append($cell);
                $lastRow = $row;
            }
            $body.append($row);
        });
        this.$renderedTable.append($body);
    };
    DefaultRenderer.prototype.getClassNames = function () {
        var classNames = [];
        classNames.push('responsive-table');
        classNames.push('responsive-table-width-' + this.options.width);
        var colCount = this.processor.getHeader().getLength();
        var colClassNames = [
            '',
            'one',
            'two',
            'three',
            'four',
            'five'
        ];
        classNames.push('responsive-table-cols-' + colClassNames[colCount]);
        if (true === this.options.zebra) {
            classNames.push('responsive-table-zebra');
        }
        return classNames;
    };
    DefaultRenderer.prototype.addClassNames = function () {
        var classNames = this.getClassNames();
        this.$renderedTable.addClass(classNames.join(' '));
    };
    DefaultRenderer.prototype.addEventDelegation = function () {
    };
    DefaultRenderer.prototype.render = function () {
        this.createEmptyTable();
        this.createColGroup();
        this.createHeader();
        this.createBody();
        this.checkIconsAndText();
        this.addClassNames();
        // this.tableComplete();
        this.addEventDelegation();
        return this.$wrapper;
    };
    DefaultRenderer.prototype.afterRendered = function () {
    };
    return DefaultRenderer;
}());
exports.default = DefaultRenderer;

},{}],8:[function(require,module,exports){
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var renderer_default_1 = require("./renderer-default");
var SlidingRenderer = /** @class */ (function (_super) {
    __extends(SlidingRenderer, _super);
    function SlidingRenderer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SlidingRenderer.prototype.getClassNames = function () {
        var classNames = _super.prototype.getClassNames.call(this);
        classNames.push('responsive-table-mode-sliding');
        if (true === this.options.firstColFixed) {
            classNames.push('responsive-table-has-fixed-col');
        }
        else {
            classNames.push('responsive-table-has-no-fixed-col');
        }
        return classNames;
    };
    SlidingRenderer.prototype.createEmptyTable = function () {
        this.$wrapper = $('<div>');
        this.$wrapper.addClass('responsive-table-sliding-wrapper');
        var wrap = $('<div>');
        wrap.addClass('responsive-table-wrapper');
        this.$wrapper.append(wrap);
        this.$renderedTable = $('<table>');
        this.$wrapper.find('.responsive-table-wrapper').append(this.$renderedTable);
    };
    SlidingRenderer.prototype.createBody = function () {
        _super.prototype.createBody.call(this);
        if (false === this.options.firstColFixed) {
            return;
        }
        var $fixedColWrapper = $('<div>');
        $fixedColWrapper.addClass('responsive-table-fixed-col-wrapper');
        var $fixedCol = $('<div>');
        $fixedCol.addClass('responsive-table-fixed-col');
        $fixedColWrapper.append($fixedCol);
        $fixedCol.append(this.$renderedTable.clone());
        $fixedCol.css('opacity', 1);
        this.$wrapper.append($fixedColWrapper);
    };
    SlidingRenderer.prototype.addEventDelegation = function () {
        if (false === this.options.firstColFixed) {
            return;
        }
        var $wrapper = this.$wrapper;
        /*
        if (true === vf.util.is_touch_device){
            this.$renderedTable.parent('.responsive-table-wrapper').on('touchstart, touchmove, touchend', function(){
                var threshold = $wrapper.data('fixed-col-width');
                var currentPos = $(this).scrollLeft();

                if (currentPos > threshold){
                    $wrapper.addClass('off-canvas');
                }
                else {
                    $wrapper.removeClass('off-canvas');
                }
            });
        }
        */
    };
    SlidingRenderer.prototype.afterRendered = function () {
        _super.prototype.afterRendered.call(this);
        var widthFactors = [
            1,
            1,
            1.36,
            1.81,
            2.25
        ];
        var numberOfCols = this.processor.getHeader().getLength();
        var factor = widthFactors[numberOfCols - 1];
        var width = $(this.$wrapper).width();
        $(this.$renderedTable).css('width', (factor * width) + 'px');
        if (false === this.options.firstColFixed) {
            return;
        }
        this.$wrapper.find('.responsive-table-fixed-col table').css('width', (factor * width) + 'px');
        var fixedColWidth = $(this.$wrapper).find('table.responsive-table-mode-sliding th').first().outerWidth();
        $(this.$wrapper).find('.responsive-table-fixed-col').width(fixedColWidth);
        $(this.$wrapper).attr('data-fixed-col-width', fixedColWidth);
    };
    return SlidingRenderer;
}(renderer_default_1.default));
exports.default = SlidingRenderer;

},{"./renderer-default":7}],9:[function(require,module,exports){
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var renderer_default_1 = require("./renderer-default");
var StackedRenderer = /** @class */ (function (_super) {
    __extends(StackedRenderer, _super);
    function StackedRenderer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StackedRenderer.prototype.createHeader = function () {
    };
    StackedRenderer.prototype.createBody = function () {
        var _this = this;
        var data = this.processor.getData();
        if (undefined === data || 0 === data.length) {
            return;
        }
        var $body = $('<tbody>');
        var createRow = function (html, className, accordionIndex) {
            var $row = $('<tr>');
            var $cell = $('<td>');
            $cell.html(html);
            $row.append($cell);
            if (undefined !== className) {
                $row.addClass(className);
                $cell.addClass(className);
                if ('accordionheader' === className) {
                    $cell.append($('<svg class="accordiontoogle i-xsml"><use xlink:href="/simplicity/svgdefs.svg#i-arrow-down-sml" /></svg>'));
                }
            }
            if (undefined !== accordionIndex) {
                $row.attr('data-accordion-index', accordionIndex);
            }
            $body.append($row);
            return $row;
        };
        $(data).each(function (accordionIndex) {
            var row = data[accordionIndex];
            var firstCellOfRow = row.getCell(0);
            // a subheadline
            if (_this.dataSource.getNumberOfCols() === firstCellOfRow.getColspan()) {
                createRow(firstCellOfRow.getContent(), 'subheadline');
            }
            else {
                // accordion head
                createRow(firstCellOfRow.getContent(), 'accordionheader', accordionIndex);
                // accordion content
                var header = _this.dataSource.getHeader();
                var $accordionkey = void 0;
                for (var i = 1, x = row.getLength(); i < x; i += 1) {
                    var joinedBefore = false;
                    if (undefined !== $accordionkey && true === $accordionkey.hasClass('joined-key')) {
                        joinedBefore = true;
                    }
                    $accordionkey = createRow(header[i], 'accordioncontent accordionkey', accordionIndex);
                    var cell = row.getCell(i);
                    if (1 < cell.getColspan()) {
                        $accordionkey.addClass('joined-key');
                        continue;
                    }
                    if (true === joinedBefore) {
                        $accordionkey.addClass('joined-before');
                    }
                    createRow(cell.getContent(), 'accordioncontent accordionvalue', accordionIndex);
                }
            }
        });
        this.$renderedTable.append($body);
        this.$renderedTable.find('.accordionheader').prev('.accordionvalue').addClass('last-accordionvalue');
    };
    /* istanbul ignore next */
    StackedRenderer.prototype.toggle = function () {
        var $header = $(this);
        var $icon = $header.find('svg use');
        var state = 'open';
        if ('/simplicity/svgdefs.svg#i-arrow-up-sml' === $icon.attr('xlink:href')) {
            state = 'close';
        }
        var accordionIndex = $header.data('accordion-index');
        var $content = $(this).parents('table').find('tr.accordioncontent[data-accordion-index="' + accordionIndex + '"]');
        if ('open' === state) {
            $icon.attr('xlink:href', '/simplicity/svgdefs.svg#i-arrow-up-sml');
            $content.hide();
        }
        else {
            $icon.attr('xlink:href', '/simplicity/svgdefs.svg#i-arrow-down-sml');
            $content.show();
        }
    };
    StackedRenderer.prototype.getClassNames = function () {
        var classNames = _super.prototype.getClassNames.call(this);
        classNames.push('responsive-table-mode-stacked');
        return classNames;
    };
    StackedRenderer.prototype.addEventDelegation = function () {
        this.$renderedTable.on('click', 'tr.accordionheader', this.toggle);
    };
    return StackedRenderer;
}(renderer_default_1.default));
exports.default = StackedRenderer;

},{"./renderer-default":7}],10:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cell_1 = require("./cell");
var Row = /** @class */ (function () {
    function Row(index) {
        this.cells = [];
        this.groupedWith = [];
        this.index = index;
    }
    Row.prototype.getIndex = function () {
        return this.index;
    };
    Row.prototype.getGroupName = function () {
        return this.groupName;
    };
    Row.prototype.setGroupName = function (groupName) {
        this.groupName = groupName;
    };
    Row.prototype.addGroupedWith = function (groupIndex) {
        this.groupedWith.push(groupIndex);
    };
    Row.prototype.getGroupedWith = function () {
        return this.groupedWith;
    };
    Row.prototype.isGroupsFirst = function () {
        return (this.groupedWith[0] === this.index);
    };
    Row.prototype.isGrouped = function () {
        return (0 < this.groupedWith.length);
    };
    Row.prototype.getLength = function () {
        return this.cells.length;
    };
    Row.prototype.addCell = function (content) {
        var cell = new cell_1.default(content);
        this.cells.push(cell);
    };
    Row.prototype.getCell = function (cellindex) {
        return this.cells[cellindex];
    };
    return Row;
}());
exports.default = Row;

},{"./cell":1}],11:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var frontend_1 = require("./frontend");
(function () {
    'use strict';
    vf.table = {};
    var settings = {
        target: '.mod-responsive-table'
    };
    var init = function () {
        $(settings.target).each(function (index, item) {
            if ('true' !== $(item).attr('data-initialized')) {
                new frontend_1.default($(item));
                $(item).attr('data-initialized', 'true');
            }
        });
    };
    vf['responsive-table'] = {
        settings: {},
        init: init
    };
}());

},{"./frontend":3}]},{},[11]);
