var serviceUrl = function () {

    'use strict';

    return '';
};

$(function () {

    'use strict';

    // // directly switch to master if no cookie is set to pass all requests to draftmodes files to simplicity apache
    // if ((null === $.cookie('simplicity-draft')) && (-1 !== window.location.href.indexOf('cookie-switcher.html'))) {
    //     $.cookie('simplicity-draft', 'master', { path: '/', domain: '.vodafone.de' });
    //     $.cookie('simplicity-draft', 'master', { path: '/', domain: '.vfd2-testnet.de' });
    //     $.cookie('simplicity-draft', 'master', { path: '/', domain: '.wf-de.vodafone.com' });
    //     $.cookie('simplicity-draft', 'master', { path: '/' });
    // }

    // add your draft here
    var draftModes = ['simplicity', 'pattern-library', 'scripts', 'simonly', 'vvl-simonly', 'redplus', 'funnelpage', 'mnp', 'hardware-overview', 'hardware-details', 'checkout', 'ecare', 'onelogin', 'callback', 'after-sales', 'dsl-onboarding', 'net-assistant', 'payment-usecase', 'pair-your-plan', 'factory', 'standalone', 'glasfaser-vfp', 'glasfaser-registration', 'halibri', 'gigakombi-xsell-mobile', 'gigakombi-xsell-fixed', 'gigakombi-landingpage', 'gigakombi-login', 'webchat', 'cookieswitcher', 'login', 'ion2s-infratest', 'ngx-showcase', 'vf-responsive'];

    var softLaunch = function () {

        var sessionStorage = false;
        try { sessionStorage = !!localStorage; } catch (e) { };

        if (false === sessionStorage) {
            return;
        }

        var $elem = $('label[for="nsf-softlaunch"]');

        var hasSoftLaunchMarker = window.sessionStorage.getItem('softlaunch') || false;

        if ('true' === hasSoftLaunchMarker) {
            hasSoftLaunchMarker = true;
        }

        var updateSwitchAndStorage = function () {

            if (true === hasSoftLaunchMarker) {
                $elem.addClass('checked');
                window.sessionStorage.setItem('softlaunch', 'true');
            }
            else {
                $elem.removeClass('checked');
                window.sessionStorage.removeItem('softlaunch');
            }

        };

        $elem.on('click', function () {

            hasSoftLaunchMarker = (true === hasSoftLaunchMarker) ? false : true;

            updateSwitchAndStorage();

        });

        // This switchbuttons seem to only work when clicking it
        // Toogling classes does not worked,
        // So this wired if is needed to resume on correct state if [hasSoftLaunchMarker=true]
        if (true === hasSoftLaunchMarker) {
            hasSoftLaunchMarker = false;
            window.sessionStorage.removeItem('softlaunch');
            $elem.trigger('click');
        }

    };
    if (-1 !== window.location.href.indexOf('cookie-switcher.html')) {
        softLaunch();
    }

    var clearStorage = function () {
        // check if localStorage is - assuming sessionStorage will be there too
        var localStorageEnabled = false;
        try { localStorageEnabled = !!localStorage; } catch (e) { };

        // clears the local storage upon error
        if (localStorageEnabled) {
            // Fix for webkit Browsers to bind it to window
            // https://stackoverflow.com/a/42964865
            window.onerror = localStorage.clear.bind(localStorage);
            window.onerror = localStorage.clear.bind(sessionStorage);

            // clear the storage
            window.localStorage.clear();
            window.sessionStorage.clear();
        }
    };

    var deleteAllCookies = function () {
        var arrCookies = document.cookie.split(';'),
            arrPaths = location.pathname.replace(/^\//, '').split('/'), //  remove leading '/' and split any existing paths
            hostTopLevelDomain = window.location.host.replace(/^[\w]*/, ''),
            arrTemplate = ['expires=Thu, 01-Jan-1970 00:00:01 GMT', 'path={path}', 'domain=' + hostTopLevelDomain, 'secure=']; //  array of cookie settings in order tested and found most useful in establishing a "delete"
        for (var i in arrCookies) {
            var strCookie = arrCookies[i];
            if ('string' === typeof strCookie && 0 <= strCookie.indexOf('=')) {
                var strName = strCookie.split('=')[0]; //  the cookie name
                for (var j = 1; j <= arrTemplate.length; j++) {
                    if (0 > document.cookie.indexOf(strName)) { break; } // if this is true, then the cookie no longer exist
                    else {
                        var strValue = strName + '=; ' + arrTemplate.slice(0, j).join('; ') + ';'; //  made using the temp array of settings, putting it together piece by piece as loop rolls on
                        if (1 === j) { document.cookie = strValue; }
                        else {
                            for (var k = 0; k <= arrPaths.length; k++) {
                                if (0 > document.cookie.indexOf(strName)) { break; } // if this is true, then the cookie no longer exist
                                else {
                                    var strPath = arrPaths.slice(0, k).join('/') + '/'; //  builds path line
                                    strValue = strValue.replace('{path}', strPath);
                                    document.cookie = strValue;
                                }
                            }
                        }
                    }
                }
            }
        }
        location.reload();
    };

    var getDraftModes = function (repo) {
        repo = repo || 'simplicity';
        if ('simplicity' === repo) {
            return $.getJSON('/simplicity/' + repo + '_draftmodes.json');
        } else if ('payment-usecase' === repo) {
            return $.getJSON('/simplicity/' + repo + '_draftmodes.json');
        } else if ('vf-responsive' === repo) {
            return $.getJSON('/static/' + repo + '_draftmodes.json');
        } else {
            return $.getJSON('/simplicity/' + repo + '/' + repo + '_draftmodes.json');
        }
    };

    var readDrafmodes = function (repo) {

        // get list of available draft builds on apache
        getDraftModes(repo).done(function (json) {
            repo = repo || '';
            $.each(json, function (key, value) {
                var option = $('<option />').val(value).text(value);
                $('#dropDownSwitcher_' + repo + '').append(option);
            });
        }).then(function () {
            // Pre-select active branch in dropdown
            $('#dropDownSwitcher_' + repo + '>option[value="' + $.cookie(repo + '-draft') + '"]').attr('selected', true);
            $('#dropDownSwitcher_' + repo + '').chosen({ no_results_text: 'Oops, nothing found!' }); // eslint-disable-line camelcase
        });

        return readDrafmodes;

    };

    var changeDraftCookie = function (mode, name, reload) {

        reload = (false !== reload) ? true : false;

        clearStorage();

        if ('released' === mode || 'prod' === mode) {
            $.removeCookie(name, { path: '/', domain: '.vodafone.de' });
            $.removeCookie(name, { path: '/', domain: '.vfd2-testnet.de' });
            $.removeCookie(name, { path: '/', domain: '.wf-de.vodafone.com' });
            $.removeCookie(name, { path: '/' });
        } else {
            $.cookie(name, mode, { path: '/', domain: '.vodafone.de' });
            $.cookie(name, mode, { path: '/', domain: '.vfd2-testnet.de' });
            $.cookie(name, mode, { path: '/', domain: '.wf-de.vodafone.com' });
            $.cookie(name, mode, { path: '/' });
        }

        draftBadge(mode); //  eslint-disable-line no-use-before-define

        if (true === reload) {
            location.reload();
        }

    };

    var getDraftMode = function () {

        var draftMode = 'released';

        if ('true' === $.cookie('simplicity-draft')) {
            draftMode = 'draft';
        }
        else if (null !== $.cookie('simplicity-draft')) {
            draftMode = $.cookie('simplicity-draft');
        }


        return draftMode;
    };

    var draftText = function (draftname) {

        var draftMode = getDraftMode();
        $('#draft-text').remove();
        if ('released' === draftMode || 'released' === draftname || null === $.cookie('simplicity-draft')) {
            return;
        }
        if (null !== $.cookie('payment-usecase-draft')){
            draftMode = draftMode + '<br> -Payment Usecase: ' + $.cookie('payment-usecase-draft');
        }
        $('#copyContainer').append('<div class="copy-group"><div id="draft-text" class="w-25">' + draftMode + '</div><br><br>');
        $('head').append('<style type="text/css">' + '#draft-text { margin: 20px 25px 25px 0; display: inline-block; background: #9c2aa0; box-shadow: 0 1px 3px #9c2aa0; color: #fff; padding: 10px; border-radius: 5px;} input.chosen-search-input, .fm-select{width: 100% !important; margin-bottom: 10px;} input[type="button"] {width: 150px; background: #9c2aa0; color: #fff; box-shadow: 0 1px 3px #9c2aa0; margin-left: 4px; height: 36px; font-weight: bold; border-color: black; border: 2px;} .copy-group {} .copyBtn { margin-left: 0px !important; } </style>');
    };

    var draftBadge = function (draftname) {

        var draftMode = getDraftMode();

        $('.draft-label').remove();

        if ('released' === draftMode || 'released' === draftname || null === $.cookie('simplicity-draft')) {
            return;
        }
        // Check Location before making the draft Mode Text + Copybutton
        if (-1 !== window.location.href.indexOf('cookie-switcher.html')) {
            draftText('Draftmode');
        }

        // Remove some stuff from the badge to fit in it
        draftMode = draftMode.toString().replace(/^feature-|^hotfix-|^release-/gi, '');

        var currDrafts = getCurrentDrafts();

        $('head').append('<style type="text/css">' + '.tooltips {display: inline-block;border-bottom: 1px dotted black;}.tooltips .tooltiptext {line-height:20px; rotate(+45deg);-webkit-transform: rotate(+45deg);position: fixed;bottom:400%;right: 15%;visibility: hidden;width: 400px;font-size:smaller;height: auto;background-color: #39d;color: #fff;text-align: center;border-radius: 6px;z-index: 1;}.tooltips:hover .tooltiptext {visibility: visible;}' +
            'div.draft-label{ width: 200px; height: 50px; background: #e43; position: fixed;top: auto!important;left: auto;right: -50px;bottom: 25px;text-align: center;line-height: 50px;letter-spacing: 1px;color: #f0f0f0; transform: rotate(-45deg);-webkit-transform: rotate(-45deg);background: #39d;z-index:10000;  } div.draftL:hover{ animation: marquee 8s linear infinite; } @keyframes marquee { from  { text-indent:  100% } to { text-indent: -100% } } #draft-text {     margin: 20px 25px 25px 0; display: inline-block; background: #9c2aa0; color: #fff; padding: 10px; border-radius: 5px;} input.chosen-search-input, .fm-select.chosen-select{width: 100% !important; margin-bottom: 10px;} input[type="button"] {width: 150px; background: #9c2aa0; color: #fff; margin-left: 4px; height: 36px; font-weight: bold; border-color: black; border: 2px;} .copy-group {} .copyBtn { margin-left: 0px !important; } </style>');

        $('body').prepend('<div class="tooltips draft-label ">' + '<div class="draftL">' + draftMode + '</div>' +
            '<span class="tooltiptext">' +
            currDrafts +
            '</span></div></div>');


    };

    // get current active drafts as HTML for tool tip text
    var getCurrentDrafts = function () {
        var currentDrafts = '';
        for (var i = 0; i < draftModes.length; i++) {
            if ( $.cookie(draftModes[i] + '-draft')){
                currentDrafts += '<br><b>' + draftModes[i] + ' draft: </b>' + $.cookie(draftModes[i] + '-draft');
            }
        }
        if ($.cookie('sandbox-draft')){
            currentDrafts += '<br><b>' + ' payment env: </b>' + $.cookie('sandbox-draft');
        }
        return currentDrafts;
    };

    draftBadge('Draftmode');

    // Read list of available Draft Modes
    if (-1 !== window.location.href.indexOf('cookie-switcher.html')) {
        for (var i = 0; i < draftModes.length; i++) {
            readDrafmodes(draftModes[i]);
        }
    }

    $('.clearStorage').on('click', function () {
        clearStorage();
    });

    $('.deleteAllCookies').on('click', function () {
        deleteAllCookies();
    });

    $('.enableSandbox').on('click', function () {
        changeDraftCookie('sandbox', 'sandbox-draft');
    });

    $('.disableSandbox').on('click', function () {
        changeDraftCookie('released', 'sandbox-draft');
    });
    // create dynamic dropdown for each draft
    for (var i = 0; i < draftModes.length; i++) {

        if ('simplicity' !== draftModes[i]) {
            var template = $('#draft-template').clone().appendTo('#draft-parent-template');
            template.find('h3').text('Select Draft Mode ' + draftModes[i]);
            template.find('select').attr('id', 'dropDownSwitcher_' + draftModes[i]);
            template.find('option').text('Draft Mode for ' + draftModes[i]);
            template.after('<br>');
        }
        // Listen for dropdown changes
        $('#dropDownSwitcher_' + draftModes[i]).on('change', { draft: draftModes[i] }, function (event) {
            changeDraftCookie($(this).val(), event.data.draft + '-draft');
        });

        // Listen for dropdown changes
        $('#dropDownSwitcher_net-assistant').on('change', function () {
            changeDraftCookie($(this).val(), 'net-assistant-draft');
        });

        // Listen for dropdown changes
        $('#dropDownSwitcher_dsl-onboarding').on('change', function () {
            changeDraftCookie($(this).val(), 'dsl-onboarding-draft');
        });

        // Listen for dropdown changes
        $('#dropDownSwitcher_callback').on('change', function () {
            changeDraftCookie($(this).val(), 'callback-draft');
        });
    }

    //Environment switch
    $('.envSwitch').on('click', function () {
        changeDraftCookie($(this).data('mode'), 'environment');
    });

    //Environment switch
    $('.testDxl').click(function () {
        $.cookie('dxlEnvirounmt', 'test', { path: '/', domain: '.vodafone.de' });
        $.cookie('dxlEnvirounmt', 'test', { path: '/', domain: '.vfd2-testnet.de' });
        $.cookie('dxlEnvirounmt', 'test', { path: '/', domain: '.wf-de.vodafone.com' });
        $.cookie('dxlEnvirounmt', 'test', { path: '/' });
    });

    //Environment switch
    $('.preprodDxl').click(function () {
        $.cookie('dxlEnvirounmt', 'preprod', { path: '/', domain: '.vodafone.de' });
        $.cookie('dxlEnvirounmt', 'preprod', { path: '/', domain: '.vfd2-testnet.de' });
        $.cookie('dxlEnvirounmt', 'preprod', { path: '/', domain: '.wf-de.vodafone.com' });
        $.cookie('dxlEnvirounmt', 'preprod', { path: '/' });
    });

    $('.ecommerceSwitchDelete').on('click', function () {
        $.removeCookie('shop-backend', { path: '/', domain: '.vodafone.de' });
        $.removeCookie('shop-backend', { path: '/', domain: '.vfd2-testnet.de' });
        $.removeCookie('shop-backend', { path: '/', domain: '.wf-de.vodafone.com' });
        $.removeCookie('shop-backend', { path: '/' });
        clearStorage();
    });

    //shop backend switch
    $('.ecommerceSwitch').on('click', function () {
        changeDraftCookie($(this).data('mode'), 'shop-backend');
    });

    //Set s_code.js to s_code_draft.js
    $('.scode').on('click', function () {
        changeDraftCookie($(this).data('test-mode'), 'scode-draft');
    });

    // Switch to release mode
    $('.draft-label').on('click', function () {
        changeDraftCookie('released', 'simplicity-draft');
        changeDraftCookie('released', 'payment-usecase-draft');
        changeDraftCookie('released', 'sandbox-draft');
    });

    /**
         * Request any page e.g ?draft=feature-cobalt-sprint-01-deploy and
         * draft cookie is set and page is reloaded without get parameter
         *
         * @TODO Validate incoming draftmode against draft_modes.json
         */
    var setDraftAndEnvFromParam = function () {

        var modifiedURL = window.location.href;

        var setFlashOnDraftChange = function (state, message) {
            $('body').append('<div class="cookie-switcher-flash ' + state + '">' + message + '</div>');
            setTimeout(function () {
                $('.cookie-switcher-flash').remove();
            }, 4000);
        };

        var findGetParameter = function (parameterName) {

            var result;

            var tmp = [];
            location.search
                .substr(1)
                .split('&')
                .forEach(function (item) {
                    tmp = item.split('=');
                    if (tmp[0] === parameterName) {
                        result = decodeURIComponent(tmp[1]);
                    }
                });
            return result;
        };


        var filterURL = function (paramName, value) {

            var hrefWithoutDraftGetParameter = modifiedURL;

            // remove all params
            hrefWithoutDraftGetParameter = hrefWithoutDraftGetParameter.replace(paramName + '=' + value, '');

            // clean up after removing
            hrefWithoutDraftGetParameter = hrefWithoutDraftGetParameter.replace(/\?&/, '?');
            hrefWithoutDraftGetParameter = hrefWithoutDraftGetParameter.replace(/&$/, '');
            hrefWithoutDraftGetParameter = hrefWithoutDraftGetParameter.replace('&&', '&');
            hrefWithoutDraftGetParameter = hrefWithoutDraftGetParameter.replace(/(\?+)$/, '');

            modifiedURL = hrefWithoutDraftGetParameter;
        };

        var changeDraftToGetParaDraft = function (_draftByGetParameter) {

            changeDraftCookie(_draftByGetParameter, 'simplicity-draft', false); // add cookie name via param

            filterURL('draft', _draftByGetParameter);
        };

        var setDraft = function (_draftByGetParameter) {

            var availableDrafts = [];

            getDraftModes().done(function (json) {
                $.each(json, function (key, value) {
                    availableDrafts.push(value);
                });
            }).then(function () {
                if (-1 !== $.inArray(_draftByGetParameter, availableDrafts)) {
                    setFlashOnDraftChange('success', 'Successfully changed the draft.');
                    changeDraftToGetParaDraft(_draftByGetParameter);
                    window.location.href = modifiedURL;
                } else {
                    setFlashOnDraftChange('error', 'Draft ' + _draftByGetParameter + ' does not exist.');
                }
            });
        };

        var changeUsecaseToGetParaUsecase = function (_usecaseByGetParameter) {

            changeDraftCookie(_usecaseByGetParameter, 'payment-usecase-draft', false); // add cookie name via param

            filterURL('usecase', _usecaseByGetParameter);
        };
        var setUsecaseDraft = function (_usecaseByGetParameter) {

            var availableUsecases = [];

            getDraftModes('payment-usecase').done(function (json) {
                $.each(json, function (key, value) {
                    availableUsecases.push(value);
                });
            }).then(function () {
                if (-1 !== $.inArray(_usecaseByGetParameter, availableUsecases)) {
                    setFlashOnDraftChange('success', 'Successfully changed the usecase.');
                    changeUsecaseToGetParaUsecase(_usecaseByGetParameter);
                    window.location.href = modifiedURL;
                } else {
                    setFlashOnDraftChange('error', 'Usecase ' + _usecaseByGetParameter + ' does not exist.');
                }
            });
        };
        var draftByGetParameter = findGetParameter('draft');

        var envByGetParameter = findGetParameter('env');

        var usecaseByGetParameter = findGetParameter('usecase');

        if (envByGetParameter && draftByGetParameter) {
            changeDraftCookie(envByGetParameter, 'environment', false);
            filterURL('env', envByGetParameter);
            setDraft(draftByGetParameter);
        } else if (envByGetParameter) {
            changeDraftCookie(envByGetParameter, 'environment', false);
            filterURL('env', envByGetParameter);
            window.location.href = modifiedURL;
        } else if (envByGetParameter && usecaseByGetParameter) {
            changeDraftCookie(envByGetParameter, 'environment', false);
            filterURL('env', envByGetParameter);
            setUsecaseDraft(usecaseByGetParameter);
        } else if (envByGetParameter && usecaseByGetParameter && draftByGetParameter) {
            changeDraftCookie(envByGetParameter, 'environment', false);
            filterURL('env', envByGetParameter);
            setDraft(draftByGetParameter);
            setUsecaseDraft(usecaseByGetParameter);
        } else if (usecaseByGetParameter && draftByGetParameter) {
            setDraft(draftByGetParameter);
            setUsecaseDraft(usecaseByGetParameter);
        } else if (usecaseByGetParameter) {
            setUsecaseDraft(usecaseByGetParameter);
        } else if (draftByGetParameter) {
            setDraft(draftByGetParameter);
        }
    };

    setDraftAndEnvFromParam();

    // COPY TO CLIPBOARD BUTTON
    var selectElementContents = function (el) {
        // Copy textarea, pre, div, etc.
        if (document.body.createTextRange) {
            // IE
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(el);
            textRange.select();
            textRange.execCommand('Copy');
        }
        else if (window.getSelection && document.createRange) {
            // non-IE
            var range = document.createRange();
            range.selectNodeContents(el);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Copy command was ' + msg);
            } catch (err) {
                console.log('Oops, unable to copy');
            }
        }
    };

    var makeCopyButton = function (el) {
        var copyBtn = document.createElement('input');
        copyBtn.type = 'button';
        copyBtn.className = 'w-25 copyBtn';
        el.parentNode.insertBefore(copyBtn, el.nextSibling);
        copyBtn.onclick = function () { selectElementContents(el); };

        if (document.queryCommandSupported('copy') || parseInt(42 <= navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)[2])) {
            // Copy works with IE 4+, Chrome 42+, Firefox 41+, Opera 29+
            copyBtn.value = 'Copy to Clipboard';
        }
        else {
            // Select only for Safari and older Chrome, Firefox and Opera
            copyBtn.value = 'Select All (then press CTRL+C to Copy)';
        }
    };
    /* Note: document.queryCommandSupported("copy") should return "true" on browsers that support copy
        but there was a bug in Chrome versions 42 to 47 that makes it return "false".  So in those
        versions of Chrome feature detection does not work!
        See https://code.google.com/p/chromium/issues/detail?id=476508
    */

    if (-1 !== window.location.href.indexOf('cookie-switcher.html')) {
        var draftTxt = document.getElementById('draft-text');
        if (true === draftTxt) {
            makeCopyButton(document.getElementById('draft-text'));
        }
    }

});
