/*Keywords: GDPR - DPP - Data Protection Privacy - Disable Cookies */
(function() {
    'use strict';

    vf.dataPrivacyPage = {
        // Attributes
        settings: {
            target: '.mod-dataPrivacyPage',
            apiURLForGet: '',
            apiURLForPost: ''
        },
        isPerformanceCookies: true,
        isOnlineMarketing: true,
        isNBA: true,
        isUnknownUser: false,
        performanceCookiesFlipSwitchOption: 'LI-OPT',
        onlineMarketingFlipSwitchOption: 'LI-OM',
        nbaFlipSwitchOption: 'LI-NBA',
        dataPrefixes: ['performance-cookies', 'online-marketing', 'nba'],
        // Functions
        init: function() {
            var that = this;

            /*
            if ('localhost' === window.location.hostname) {
                this.settings.apiURLForGet = '/simplicity/ajax/json/gdpr-all-options-true.json';
                this.settings.apiURLForPost = '/simplicity/ajax/json/gdpr-all-options-true.json';
            } else if ('eweb8.vfd2-testnet.de' === window.location.host) {
                this.settings.apiURLForGet = 'https://eweb9.vfd2-testnet.de/op/vfde-web/info?permissions&out=json';
                this.settings.apiURLForPost = 'https://eweb9.vfd2-testnet.de/op/vfde-web/info?permissions&out=json';
            }
            */


            // Info: Internet explorer uses MSIE for app version up until and including IE 10
            // If IE 10 or less use reverse proxy.
            if (-1 !== navigator.appVersion.indexOf('MSIE')) {
                this.settings.apiURLForGet = 'https://eweb9.vfd2-testnet.de/op/vfde-web/info?permissions&out=json';
                this.settings.apiURLForPost = 'https://eweb9.vfd2-testnet.de/op/vfde-web/info?permissions&out=json';
            }
            else {
                this.settings.apiURLForGet = 'https://fc.vodafone.de/op/vfde-web/info?permissions&out=json';
                this.settings.apiURLForPost = 'https://fc.vodafone.de/op/vfde-web/info?permissions&out=json';
            }

            if (!this.cookiesEnabled()){
                this.isPerformanceCookies = false;
                this.isOnlineMarketing = false;
                this.isNBA = false;
                $(this.settings.target).find('.flip-switches-errorCookiesDisabled').removeClass('displayNone');
                this.disableAllFlipSwitches();
                this.handleUnknownUser();
                return;
            }

            var flipSwitches = $(this.settings.target).find('.fm-flip-switch');

            flipSwitches.each(function() {
                var input = $(this).find('.cmn-toggle');
                $(input).prop('checked', false);
            });

            // Call get function
            this.getGDPRSettings()
                // On Promise resolve
                .done(function (response){
                    that.getGDPRSettingsSuccess(response);
                })
                // On Promise reject
                .fail(function (error){
                    that.getGDPRSettingsFailure(error);
                });
        },
        setGDPRSettings: function(flipSwitchOption, isFlipSwitchOn) {
            var deferred = $.Deferred();

            var gdprSettingPayload = {
                permission: {}
            };

            gdprSettingPayload.permission[flipSwitchOption] = isFlipSwitchOn;

            $.ajax({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                url: this.settings.apiURLForPost,
                data: JSON.stringify(gdprSettingPayload),
                dataType: 'text',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            }).done(function (response) {
                deferred.resolve(response);
            }).fail(function (error) {
                deferred.reject(error);
            });

            return deferred.promise();
        },
        handleUnknownUser: function () {
            // Hide the NBA flip switch when unknown user
            $('#cmn-toggle-NBA').parents('.fm-data').remove();
        },
        handleTopWarning: function () {
            var topWarning = $(this.settings.target).find('.warningAllOff');
            var warningTitle = '';
            var warningText = '';

            // If online marketing and do not track is enabled in the browser
            if (this.doNotTrack() && this.isOnlineMarketing){
                $(topWarning).removeClass('displayNone');

                //Get title and text of top warning
                warningTitle = $(this.settings.target).data('warning-do-not-track-heading');
                warningText = $(this.settings.target).data('warning-do-not-track-text');

            } else {
                if (!this.isPerformanceCookies || !this.isOnlineMarketing || (!this.isNBA && !this.isUnknownUser)) {
                    $(topWarning).removeClass('displayNone');
                } else {
                    $(topWarning).addClass('displayNone');
                }

                //Get title and text of top warning
                warningTitle = $(this.settings.target).data('warning-switch-off-heading');
                warningText = $(this.settings.target).data('warning-switch-off-text');
            }

            //Set title and text of top warning
            $(topWarning).find('h4').html(warningTitle);
            $(topWarning).find('p').html(warningText);
        },
        getGDPRSettings: function() {
            var deferred = $.Deferred();

            $.ajax({
                method: 'get',
                url: this.settings.apiURLForGet,
                dataType: 'json',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            }).done(function (response) {
                deferred.resolve(response);
            }).fail(function (error) {
                deferred.reject(error);
            });

            return deferred.promise();
        },
        disableAllFlipSwitches: function () {
            var that = this;
            var flipSwitches = this.settings.target + ' .fm-flip-switch';

            //Set flip switches to off.
            $(flipSwitches).each(function(index, flipSwitch) {
                that.setFlipSwitchTexts($(flipSwitch), false, that.dataPrefixes[index]);
            });

            $(flipSwitches + ' .cmn-toggle').attr('disabled', '');
            $(flipSwitches + ' .cmn-toggle-label').addClass('flipSwitchDisabled');
        },
        hideAllFlipSwitches: function () {
            // elements which contain all the switches
            var $fmDataElements = $(this.settings.target + ' .fm-flip-switch').parents('.fm-data');
            $fmDataElements.hide();
        },
        getGDPRSettingsFailure: function () {
            // Show error message
            $(this.settings.target).find('.flip-switches-error').removeClass('displayNone');

            // Hide other top messages
            $(this.settings.target).find('.warningAllOff').addClass('displayNone');

            // //Turn on all switches
            var flipSwitches = $(this.settings.target).find('.fm-flip-switch');

            flipSwitches.each(function() {
                var input = $(this).find('.cmn-toggle');
                $(input).prop('checked', true);
            });

            this.hideAllFlipSwitches();
            this.handleUnknownUser();
        },
        getGDPRSettingsSuccess: function(response) {
            var gdprSettings = response.permissions;

            this.isPerformanceCookies = gdprSettings[this.performanceCookiesFlipSwitchOption] === undefined ? true : gdprSettings[this.performanceCookiesFlipSwitchOption];
            this.isOnlineMarketing = gdprSettings[this.onlineMarketingFlipSwitchOption] === undefined ? true : gdprSettings[this.onlineMarketingFlipSwitchOption];
            this.isNBA = gdprSettings[this.nbaFlipSwitchOption] === undefined ? true : gdprSettings[this.nbaFlipSwitchOption];

            this.isUnknownUser = (0 === response.state) ? true : false;

            if (this.isUnknownUser) {
                this.handleUnknownUser();
            }

            this.handleTopWarning();

            this.initializeSwitches();
        },
        initializeSwitches: function() {
            var flipSwitches = $(this.settings.target).find('.fm-flip-switch');
            var that = this;

            flipSwitches.each(function() {
                var input = $(this).find('.cmn-toggle');
                var thisFlipSwitch = this;
                var switchIsNBA = (0 <= input.attr('id').indexOf('NBA'));
                var switchIsOnlineMarketing = (0 <= input.attr('id').indexOf('onlineMarketing'));
                var switchIsPerformanceCookies = (0 <= input.attr('id').indexOf('performanceCookies'));

                var dataPrefix = '';

                if (switchIsNBA) {
                    dataPrefix = 'nba';
                } else if (switchIsOnlineMarketing) {
                    dataPrefix = 'online-marketing';
                } else if (switchIsPerformanceCookies) {
                    dataPrefix = 'performance-cookies';
                }

                // Set all switches to "on" if it's enabled in the response
                if (that.isPerformanceCookies && switchIsPerformanceCookies) {
                    $(input).prop('checked', true);
                } else if (that.isOnlineMarketing && switchIsOnlineMarketing) {
                    $(input).prop('checked', true);
                } else if (that.isNBA && switchIsNBA) {
                    $(input).prop('checked', true);
                }

                var isFlipSwitchOn = $(input).is(':checked');
                that.setFlipSwitchTexts($(thisFlipSwitch), isFlipSwitchOn, dataPrefix);

                // Switch event handler
                input.on('change', function() {
                    var warningOn = $(thisFlipSwitch).find('.warningOn');
                    var warningOff = $(thisFlipSwitch).find('.warningOff');

                    var warningBoxToDisplay = isFlipSwitchOn ? warningOn : warningOff;

                    var isWarningBoxShowing = !warningOn.hasClass('displayNone') || !warningOff.hasClass('displayNone');

                    // Show/hide warning box
                    if (isWarningBoxShowing) {
                        warningOn.addClass('displayNone');
                        warningOff.addClass('displayNone');
                    }
                    else {
                        warningBoxToDisplay.removeClass('displayNone');
                        that.setWarningText(warningBoxToDisplay, !isFlipSwitchOn, $(thisFlipSwitch), dataPrefix);
                    }

                    // Hide success/error message boxes
                    $(thisFlipSwitch).find('.success').addClass('displayNone');
                    $(thisFlipSwitch).find('.flip-switch-error').addClass('displayNone');

                });

                // On confirmation warning save click
                $(thisFlipSwitch).find('.warning-yes').on('click', function(event) {
                    // Prevent link from triggering redirect
                    event.preventDefault();

                    isFlipSwitchOn = $(input).is(':checked');

                    var flipSwitchOption = that.getFlipSwitchOptionToPost(input.attr('id'));

                    that.setGDPRSettings(flipSwitchOption, isFlipSwitchOn)
                        // On Promise resolve
                        .done(function() {
                            that.setGDPRSettingsSuccess(flipSwitchOption, isFlipSwitchOn, $(thisFlipSwitch), dataPrefix);

                            // Hide warning box and display success message.
                            $(thisFlipSwitch).find('.warning').addClass('displayNone');
                            $(thisFlipSwitch).find('.success').removeClass('displayNone');
                        })
                        // On promise reject
                        .fail(function() {
                            // Hide warning, show error and reverse toggle switch.
                            $(thisFlipSwitch).find('.warning').addClass('displayNone');
                            $(thisFlipSwitch).find('.flip-switch-error').removeClass('displayNone');
                            $(input).prop('checked', !$(input).is(':checked'));
                        });
                });

                // On confirmation warning abbrechen/cancel click
                $(thisFlipSwitch).find('.warning').find('.warning-no').on('click', function(event) {
                    event.preventDefault();
                    $(thisFlipSwitch).find('.warning').addClass('displayNone');

                    $(input).prop('checked', !$(input).is(':checked'));
                });

            });
        },
        getFlipSwitchOptionToPost: function(inputId) {
            if (0 <= inputId.indexOf('performanceCookies')) {
                return this.performanceCookiesFlipSwitchOption;
            } else if (0 <= inputId.indexOf('onlineMarketing')) {
                return this.onlineMarketingFlipSwitchOption;
            } else if (0 <= inputId.indexOf('NBA')) {
                return this.nbaFlipSwitchOption;
            }

            return '';
        },
        setGDPRSettingsSuccess: function(flipSwitchOption, isFlipSwitchOn, thisFlipSwitch, dataPrefix) {
            switch (flipSwitchOption) {
            case this.performanceCookiesFlipSwitchOption:
                this.isPerformanceCookies = isFlipSwitchOn;
                break;
            case this.onlineMarketingFlipSwitchOption:
                this.isOnlineMarketing = isFlipSwitchOn;
                break;
            case this.nbaFlipSwitchOption:
                this.isNBA = isFlipSwitchOn;
                break;
            default:
                break;
            }


            this.setFlipSwitchTexts(thisFlipSwitch, isFlipSwitchOn, dataPrefix);

            this.handleTopWarning();

            if ('localhost' !== window.location.hostname) {
                window.loadFcData();
            }
        },
        /*https://github.com/Modernizr/Modernizr/commit/33f00fbbeb12e92bf24711ea386e722cce6f60cc*/
        cookiesEnabled: function(){
            // Quick test if browser has cookieEnabled host property
            if (navigator.cookieEnabled) {return true;}
            // Create cookie
            document.cookie = 'cookietest=1';
            var ret = -1 != document.cookie.indexOf('cookietest=');
            // Delete cookie
            document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
            return ret;
        },
        doNotTrack: function() {

            if ('1' === window.doNotTrack ||
                1 === window.doNotTrack ||
                'yes' === navigator.doNotTrack ||
                '1' === navigator.doNotTrack ||
                1 === navigator.doNotTrack ||
                '1' === navigator.msDoNotTrack ||
                1 === navigator.msDoNotTrack) {
                return true;
            }

            return false;
        },
        setFlipSwitchTexts: function(thisFlipSwitch, isFlipSwitchOn, dataPrefix) {
            var tdLeft = $(thisFlipSwitch.find('.td-left'));

            if (isFlipSwitchOn) {
                tdLeft.find('.flip-switch-text-heading').empty();
                tdLeft.find('.flip-switch-text').html($(this.settings.target).data(dataPrefix + '-on-text'));
            } else {
                tdLeft.find('.flip-switch-text-heading').html($(this.settings.target).data(dataPrefix + '-off-text-heading'));
                tdLeft.find('.flip-switch-text').html($(this.settings.target).data(dataPrefix + '-off-text'));
            }

        },
        setWarningText: function (warningBoxToDisplay, isFlipSwitchOn, thisFlipSwitch, dataPrefix) {
            var alertContent = $(warningBoxToDisplay.find('.alert-content'));

            if (isFlipSwitchOn) {
                alertContent.find('p').html($(this.settings.target).data(dataPrefix + '-on-warning-text'));
                warningBoxToDisplay.find('.warning-yes').html($(this.settings.target).data(dataPrefix + '-on-warning-button-save'));
            } else {
                alertContent.find('h4').html($(this.settings.target).data(dataPrefix + '-off-warning-heading'));
                alertContent.find('p').html($(this.settings.target).data(dataPrefix + '-off-warning-text'));
                warningBoxToDisplay.find('.warning-yes').html($(this.settings.target).data(dataPrefix + '-off-warning-button-save'));
            }
        }

    };

}(vf));
