(function () {
    'use strict';

    // @TOD BPe -> This script fires on domready , create custom event that is trigger on handlebars rendered

    vf['device-overview'] = {
        settings: {
            target: '.device-overview',
            animateScroll: true,
            snapping: false
        },

        init: function (options) {

            var s = this.settings;

            var self = {
                options: options,
                scope: this
            };

            if (options && options.context && $(s.target, options.context) && $(s.target, options.context).find('.scroll-pane')
            && $(s.target, options.context).find('.scroll-pane').data && $(s.target, options.context).find('.scroll-pane').data('jsp')) {

                $(s.target, options.context).find('.scroll-pane').data('jsp').destroy();
            }

            $(s.target, (options) ? options.context : null).addBack(s.target).each(function () {

                var $this = $(this);

                var getSliderWidth = function () {
                    return $this.find('.device-module-tiles-wrapper').width();
                };

                var getItemsCount = function () {
                    return $this.find('.device-module-tiles .device-module-tile').length;
                };
                var getItemWidth = function () {

                    var count = (getItemsCount());

                    if (3 >= count) {

                        $this.find('.row-next').hide();

                        $this.find('.row-prev').hide();
                    }

                    if ('desktop' === vf.util.layout(true)) {

                        if ($this.find('.device-module-tiles-wrapper').hasClass('scroll-pane')) {

                            s.width = (getSliderWidth() / 100 * 33.33333333333);

                            $this.find('.device-module-tiles').css('width', s.width * count + 'px');

                            $this.find('.device-module-tile').css('width', s.width + 'px');

                        } else {

                            s.width = (getSliderWidth() / 100 * 33.33333333333);

                            $this.find('.device-module-tiles').css('width', '100%');

                            $this.find('.device-module-tile').css('width', s.width + 'px');
                        }
                    }

                    if ('tablet' === vf.util.layout(true)) {

                        s.width = (getSliderWidth() / 100 * 41.66666666666);

                        var count = (getItemsCount());

                        $this.find('.device-module-tiles').css('width', s.width * count + 'px');

                        $this.find('.device-module-tile').css('width', s.width + 'px');
                    }

                    if ('mobile' === vf.util.layout(true)) {

                        s.width = ((getSliderWidth() - 3) / 100 * 83.33333333333);

                        var count = (getItemsCount());

                        $this.find('.device-module-tiles').css('width', s.width * count + 'px');

                        $this.find('.device-module-tile').css('width', s.width + 'px');
                    }
                };

                getItemWidth();

                var pane = $this.find('.scroll-pane');

                pane.jScrollPane(s);

                var api = pane.data('jsp');

                if (options && options.selectedTile) {

                    setTimeout(function(){
                        api.scrollToElement($this.find(options.selectedTile), true, true);
                    }, 1000);
                }

                $(window).on('vf::resize', function () {

                    window.clearTimeout(s.timer3);

                    s.timer3 = setTimeout(function () {

                        self.scope.init(self.options);
                    }, 10);
                });

                /**
                 * Init jScrollPane only on touch devices
                 */
                function isTouchDevice() {
                    return (('ontouchstart' in window) || (0 < navigator.MaxTouchPoints) || (0 < navigator.msMaxTouchPoints));
                }

                $this.find('.scroll-pane').on('jsp-scroll-x', function (event, srollPositionX, isAtLeft, isAtRight) {

                    if (undefined !== s.timer) {
                        window.clearTimeout(s.timer);
                    }

                    if (true === s.snapping) {
                        return;
                    }

                    if (isAtLeft) {
                        $this.find('.row-prev').hide();
                    } else if ( 3 < getItemsCount()) {
                        $this.find('.row-prev').show();
                    }

                    if (isAtRight) {
                        $this.find('.row-next').hide();
                    } else if ( 3 < getItemsCount()) {
                        $this.find('.row-next').show();
                    }

                    s.timer = window.setTimeout(function () {

                        s.snapping = true;

                        var pos = Math.round(api.getContentPositionX() / s.width);

                        if (0 === pos) {

                            api.scrollToX((pos * s.width), true);
                        } else {

                            api.scrollToX((pos * s.width) + 1, true);
                        }

                        window.clearTimeout(s.timer2);

                        s.timer2 = window.setTimeout(function () {

                            if (isAtLeft) {
                                $this.find('.row-prev').hide();
                            } else if ( 3 < getItemsCount()){
                                $this.find('.row-prev').show();
                            }

                            if (isAtRight) {
                                $this.find('.row-next').hide();
                            } else if ( 3 < getItemsCount()){
                                $this.find('.row-next').show();
                            }
                        }, 0);

                        s.timer2 = window.setTimeout(function () {
                            s.snapping = false;
                        }, 300);
                    }, 100);
                });

                if (!isTouchDevice()) {

                    if (undefined !== api && null !== api) {
                        if (-0 === api.getPercentScrolledX()) {
                            $this.find('.row-prev').hide();
                        }
                        if (1 === api.getPercentScrolledX()) {
                            $this.find('.row-next').hide();
                        }
                    }

                    pane.bind(
                        'jsp-scroll-x',
                        function (event, srollPositionX, isAtLeft, isAtRight) {
                            if (isAtLeft) {
                                $this.find('.row-prev').hide();
                            } else if ( 3 < getItemWidth()) {
                                $this.find('.row-prev').show();
                            }

                            if (isAtRight) {
                                $this.find('.row-next').hide();
                            } else if ( 3 < getItemWidth()) {
                                $this.find('.row-next').show();
                            }
                        }
                    );

                    $this.on('click', '.row-next', function () {
                        api.scrollByX($this.find('.device-module-tile')[0].getBoundingClientRect().width, true);
                    });

                    $this.on('click', '.row-prev', function () {
                        api.scrollByX(-$this.find('.device-module-tile')[0].getBoundingClientRect().width, true);
                    });
                } else {
                    if ('desktop' === vf.util.layout(true)) {
                        if (0 === $this.find('.device-module-tiles').position().left) {
                            $this.find('.row-prev').hide();
                        }

                        if (-1 * ($this.find('.device-module-tiles').width() - $this.find('.scroll-pane').width()) === $this.find('.device-module-tiles').position().left) {
                            $this.find('.row-next').hide();
                        }

                        $this.find('.scroll-pane').on('scroll', function () {
                            if (0 === $this.find('.device-module-tiles').position().left) {
                                $this.find('.row-prev').hide();
                            } else if ( 3 < getItemsCount()){
                                $this.find('.row-prev').show();
                            }

                            if (-1 * ($this.find('.device-module-tiles').width() - $this.find('.scroll-pane').width()) === $this.find('.device-module-tiles').position().left) {
                                $this.find('.row-next').hide();
                            } else if ( 3 < getItemsCount()){
                                $this.find('.row-next').show();
                            }

                        });

                        $this.on('click', '.row-next', function () {
                            api.scrollByX($this.find('.device-module-tile')[0].getBoundingClientRect().width, true);
                        });

                        $this.on('click', '.row-prev', function () {
                            api.scrollByX(-$this.find('.device-module-tile')[0].getBoundingClientRect().width, true);
                        });
                    }
                }
            });
        },
    };
}(vf));
