(function () {
    'use strict';

    vf['glnav-my-vf-login'] = {
        settings: {
            target: '.mod-glnav-my-vf-login',
            usenameFormatValid: false,
            passwordFormatValid: false

        },
        /**
         * init function
         */
        init: function () {
            var me = vf['glnav-my-vf-login'];
            var $inputUsername = $(me.settings.target).find('#input-username');
            var $inputPassword = $(me.settings.target).find('#input-password');
            var $loginBtn = $(me.settings.target).find('.login-btn:submit');
            // init tooltips
            $('.glnav .tg-icon').on('click', function () {
                var $infoItem = $(this).parent().parent().find('.mod-info-toggle');
                $infoItem.toggleClass('info-active');
                // also adjust the y position if necessary
                var $box = $(this).parent().parent().find('.fm-forminfo-ol-wrapper');
                var regularHeight = 67;
                var actualHight = $box.height();
                if (actualHight > regularHeight){
                    var diff = actualHight - regularHeight;
                    $box.css('top', 'calc(50% - ' + diff / 2 + 'px)' );
                }
            });

            // type handlers
            $inputUsername.on('input propertychange focusout', function() {
                me.checkUsernameFormat();
                me.checkInputFormat();

            });

            $inputPassword.on('input propertychange focusout', function() {
                me.checkPasswordFormat();
                me.checkInputFormat();
            });
            if ($loginBtn.length) {
                $loginBtn.on('click', function () {
                    me.setLoginSession();
                });
            }
        },

        /**
         * check username format
         */
        checkInputFormat: function (){
            var me = vf['glnav-my-vf-login'];

            if (me.settings.usenameFormatValid && me.settings.passwordFormatValid){
                $(me.settings.target).find('.login-btn').prop('disabled', false);
            } else {
                $(me.settings.target).find('.login-btn').prop('disabled', true);
            }

        },

        /**
         * check username format
         */
        checkUsernameFormat: function (me){
            var me = vf['glnav-my-vf-login'];
            var $inputUsername = $(me.settings.target).find('#input-username');
            if ('' === $inputUsername.val()){
                $inputUsername.addClass('fm-error-border');
                $inputUsername.parent().parent().find('.error-body').css('display', 'block');
                me.settings.usenameFormatValid = false;
            } else {
                $inputUsername.removeClass('fm-error-border');
                $inputUsername.parent().parent().find('.error-body').css('display', 'none');
                me.settings.usenameFormatValid = true;
            }
        },
        /**
         * check password format
         */
        checkPasswordFormat: function (){
            var me = vf['glnav-my-vf-login'];
            var $inputPassword = $(me.settings.target).find('#input-password');
            if ('' === $inputPassword.val()){
                $inputPassword.addClass('fm-error-border');
                $inputPassword.parent().parent().find('.error-body').css('display', 'block');
                me.settings.passwordFormatValid = false;


            } else {
                $inputPassword.removeClass('fm-error-border');
                $inputPassword.parent().parent().find('.error-body').css('display', 'none');
                me.settings.passwordFormatValid = true;
            }
        },
        setLoginSession: function () {
            VF.setSessionStorage('overlay-login', 'true');
        },
    };
}(vf));
