(function () {//eslint-disable-line strict

    vf.youtube = {
        settings: {
            target: '.mod-youtube',
            defaultHeightSml: 305,
            defaultHeightMed: 500,
            templates: {
                play: '<a href="#" class="youtube-play"><span class="access">{{play}}</span></a>',
                video: '<img src="//i.ytimg.com/vi/{{id}}/hqdefault.jpg">'
            }
        },
        init: function (context) {
            var s = this.settings,
                templates = s.templates;
            $(s.target, context).addBack(s.target).each(function () {

                var youtube = $(this).find('.youtube'),
                    id = youtube.attr('id').replace('?', ''),
                    play;

                youtube.append(templates.play.replace('{{play}}', vf.config.string.play));
                youtube.append(templates.video.replace('{{id}}', id));

                play = $(this).find('.youtube-play');
                play.on('click', function (e) {
                    youtube.append('<iframe height="' + youtube.height() + '" width="100%" src="https://www.youtube.com/embed/' + id + '?controls=1&autoplay=1&enablejsapi=1&version=3" frameborder="0" allowfullscreen></iframe>');
                    e.preventDefault();
                    youtube.find('a, img').addClass('access');
                });
                /* Autoplay is not allowed on mobile devices
                 * Default Height has to be specified because on modul load the height of the responsive container cannot be read.
                 */
                //if Tablet show video directly with the default height from settings and no autoplay
                //if Tablet show video directly with the default height from settings and no autoplay
                if ('sml' === vf.util.layout()) {
                    youtube.append('<iframe height="' + youtube.height() + '" width="100%" src="https://www.youtube.com/embed/' + id + '?controls=1&autoplay=0&enablejsapi=1&version=3" frameborder="0" allowfullscreen></iframe>');
                    youtube.find('a, img').addClass('access');
                }
            });
        },
    };
}(vf));
