(function () {
    vf['input-toggle'] = {
        settings: {
            inputToggle: '.mod-input-toggle'
        },

        init: function (context) {
            var s = this.settings;

            $(s.inputToggle, context).each(function () {
                var input = $('input', this);

                $('button', this).on('click', function (e) {

					//replace the text with the data-changetext value
                    var original_text = $(this).html();
                    var change_text = $(this).data('changetext');
                    $(this).html(change_text);
                    $(this).data('changetext', original_text);
					//end text replace

                    var eachInput = $(this).closest('.fm-data').find('input'),
                        clone = '<input type="text" value="' + eachInput.val() + '" id="' + eachInput.attr('id') + '" name="' + eachInput.attr('name') + '" autocomplete="off">';

                    if ('password' == eachInput.attr('type')) {
                        eachInput.replaceWith(clone);
                    } else {
                        eachInput.replaceWith(clone.replace('type="text"', 'type="password"'));
                    }

                    e.preventDefault();
                });
            });
        }
    };
}(vf));
